
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { CartModule } from "@/store/cart";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";
import BRow from "@/components/bs/BRow.vue";
import BCol from "@/components/bs/BCol.vue";

@Component({
  components: { BCol, BRow },
})
export default class OrderConfirmation extends Vue {
  @VModel({ type: Object, default: () => ({}) }) orderProperty!: Record<
    string,
    any
  >;
  get cartData(): CartComponentItemsData | null {
    return CartModule.items;
  }
}
